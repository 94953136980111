
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body{font-family:'Open Sans'; background-color:rgba(0, 0, 0, .05);}

.main-col{/*background:linear-gradient(to right, rgba(95, 44, 130, .2), rgba(73, 160, 157, .2));*/}

.main-container{padding:15px 25px 15px 25px;}

.highlight{animation:blinkingBackground 1s infinite;}
@keyframes blinkingBackground{0% { background-color: #fcff32;} 50% { background-color: #fff;}}

.gr{background:rgb(42,35,108); background:linear-gradient(to right, #fc5c7d, #6a82fb);}
.gr-reverse{background:rgb(42,35,108); background:linear-gradient(to right, #fc5c7d, #6a82fb);}

.gr-light{background:linear-gradient(to right, rgba(95, 44, 130, .2), rgba(73, 160, 157, .2));}

.align-start{display:flex; align-items:flex-start; justify-content:center;}
.align-center{display:flex; align-items:center; justify-content:center;}
.justify-start{display:flex; align-items:center; justify-content:flex-start;}
.justify-center{display:flex; align-items:flex-start; justify-content:center;}
.justify-end{display:flex; align-items:center; justify-content:flex-end;}
.align-end{display:flex; align-items:flex-end; justify-content:center;}

.space-between{display:flex; align-items:center; justify-content:space-between;}

input:focus-visible{outline:none;}

.flex-col,
.vertical{display:flex; flex-direction:column;}

.m-0{margin:0 !important;}
.mb-0{margin-bottom:0 !important;}

.op-3{opacity:.3;}
.op-4{opacity:.4;}
.op-5{opacity:.5;}
.op-6{opacity:.6;}
.op-7{opacity:.7;}
.op-8{opacity:.8;}
.op-9{opacity:.9;}

.gr-2{color:rgba(0,0,0,.2)}
.gr-3{color:rgba(0,0,0,.3)}
.gr-4{color:rgba(0,0,0,.4)}
.gr-5{color:rgba(0,0,0,.5)}
.gr-6{color:rgba(0,0,0,.6)}
.gr-7{color:rgba(0,0,0,.7)}
.gr-8{color:rgba(0,0,0,.8)}
.gr-9{color:rgba(0,0,0,.9)}

.fs-10{font-size:10px;}
.fs-11{font-size:11px;}
.fs-12{font-size:12px;}
.fs-13{font-size:13px;}
.fs-14{font-size:14px;}
.fs-16{font-size:16px;}
.fs-18{font-size:18px;}
.fs-20{font-size:20px;}
.fs-24{font-size:24px;}
.fs-40{font-size:40px;}
.fs-50{font-size:50px;}

.fw-normal{font-weight:normal;}
.fw-bold{font-weight:bold;}
.fw-semibold{font-weight:600;}

.ml-2{margin-left:2px !important}
.ml-5{margin-left:5px !important;}
.ml-10{margin-left:10px !important;}
.ml-20{margin-left:20px !important;}
.ml-30{margin-left:30px !important;}

.mr-2{margin-right:2px !important;}
.mr-5{margin-right:5px !important;}
.mr-10{margin-right:10px !important;}

.mt-2{margin-top:2px !important;}
.mt-5{margin-top:5px !important;}
.mt-10{margin-top:10px !important;}
.mt-20{margin-top:20px !important;}

.mb-5{margin-bottom:5px !important;}
.mb-10{margin-bottom:10px !important;}
.mb-20{margin-bottom:20px !important;}

.mr-10{margin-right:10px !important;}

.p-5{padding:5px}
.p-0{padding:0;}

.pt-5{padding-top:5px;}
.pt-10{padding-top:10px;}

.c-primary{color:#5e6bf4}
.c-blue{color:rgb(106, 130, 251) !important}
.c-green{color:rgb(0, 139, 139) !important}
.c-red{color:rgb(211, 47, 47) !important}
.c-w{color:#fff !important}

.lh-1{line-height:1;}
.lh-11{line-height:1.1;}
.lh-12{line-height:1.2;}
.lh-13{line-height:1.3;}
.lh-14{line-height:1.4;}
.lh-15{line-height:1.5;}
.lh-2{line-height:2;}

.login-container{width:100%;}
.login-container img{width:100%}
.login-box{position:fixed; left:35%; width:30%;}

.login-header{background-color:#00652E; padding:15px 20px; display:flex; align-items:center; justify-content:center; border-radius:15px 15px 0 0;}
.login-body{background-color:#fff; padding:50px; border-radius:0 0 15px 15px;}

.has-hoverable-action:hover .hoverable-action{opacity:1}
.hoverable-action{opacity:0; transition:.2s all ease-in-out}

.hoverable{transition:.2s all ease-in-out;}
.hoverable:hover{background-color:rgba(0, 0, 0, .02);}

.capitalize{text-transform:capitalize;}
.lowercase{text-transform:lowercase;}
.uppercase{text-transform:uppercase;}

.right-aligned .MuiInputBase-input{text-align:right;}

.header-wrapper{display:flex; justify-content:space-between; align-items:center; margin:0 25px; padding:0;}

.main-title-block{margin-bottom:5px; padding-bottom:10px; border-bottom:1px solid rgba(0,0,0,.2); display:flex; justify-content:space-between; align-items:center;}
.main-title-block h1{margin:0; font-size:22px;}

.main-menu-wrapper{position:relative; height:100vh; z-index:999; background-color:transparent;}
.main-menu-block{position:relative; z-index:999; height:100vh; border:0 none; background-color:#006B2F; /*background:hsla(312, 66%, 76%, 1); background: linear-gradient(90deg, hsla(312, 66%, 76%, 1) 0%, hsla(234, 93%, 67%, 1) 100%); background: -moz-linear-gradient(90deg, hsla(312, 66%, 76%, 1) 0%, hsla(234, 93%, 67%, 1) 100%); background: -webkit-linear-gradient(90deg, hsla(312, 66%, 76%, 1) 0%, hsla(234, 93%, 67%, 1) 100%); filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EA98DA", endColorstr="#5B6CF9", GradientType=1 );*/ box-shadow:-15px 0 15px inset rgba(0,0,0,.1)}

.primary-menu{list-style-type:none; padding:0; margin:0; display:flex; flex-direction:column; align-items:center; justify-content:center;}
.primary-menu > li{display:flex; align-items:center; justify-content:center; flex-direction:column; cursor:pointer; flex:1; width:100%;}
.primary-menu > li > a{display:flex; align-items:center; justify-content:center; flex-direction:column; padding:10px}

.primary-menu > li.has-childs{padding:10px 0; transition:.2s all ease-in-out;}
.primary-menu > li.has-childs:hover{background-color:rgba(0, 0, 0, .1);}

.primary-menu > li:last-child{border:0 none}

.primary-menu > li.current{background-color:rgba(0,0,0, .35);}

.primary-menu > li > a{color:rgba(255,255,255, .8); text-decoration:none;}
.primary-menu > li > .icon,
.primary-menu > li > a > .icon{color:rgba(255,255,255, .6); height:22px;}
.primary-menu > li > .label{color:rgba(255,255,255, .8); line-height:1; font-weight:bold;}

.primary-menu > li > .icon .material-icons,
.primary-menu > li > a > .icon .material-icons{font-size:20px; color:rgba(255,255,255, .5)}

.submenu-wrapper{width:0; left:0;}
.submenu-wrapper.shown{width:1280px; height:100vh; position:absolute; left:0; top:0;}

.submenu{background-color:#ffff; width:240px; height:100vh; top:0; left:-240px; position:absolute; box-shadow:5px 0px 15px rgba(0, 0, 0, .05); transition:.3s all ease-in-out; z-index:9;}
.submenu-wrapper.shown .submenu{left:90px;}
/* .submenu.show{left:90px;}
.submenu.shown{width:240px} */

.submenu ul{list-style-type:none; margin:0; padding:0;}
.submenu > ul{margin:20px 25px;}
.submenu > ul > li{margin-bottom:20px;}
.submenu > ul > li > strong{font-size:13px; text-transform:uppercase; color:rgba(0, 0, 0, .5)}
.submenu > ul > li > ul{margin-top:6px;}
.submenu > ul > li > ul li{}
.submenu > ul > li > ul li a{padding:5px 0; font-size:13px; font-weight:600; color:rgba(0, 0, 0, .7); position:relative; display:flex; align-items:center; text-decoration:none; color:rgb(106, 130, 251); transition:.2s all ease-in-out}
.submenu > ul > li > ul li a:hover{color:rgb(81, 105, 224); background-color:rgba(0, 0, 0, .03); padding:5px; border-radius:5px;}

.no-data-container{width:100%; align-items:center; justify-content:center; display:flex;}
.no-data{width:400px; align-items:center; display:flex; justify-content:center; flex-direction:column;}
.no-data img{width:100%}
.no-data .no-data-icon .MuiSvgIcon-root{font-size:100px; color:rgba(0, 0, 0, .1)}
.no-data div.text{font-weight:bold; font-size:30px; color:rgba(0, 0, 0, .2); text-transform:capitalize;}
.no-data-container.small{margin-top:50px}
.no-data-container.small img{width:120px;}
.no-data-container.small div.text{font-size:15px; color:rgba(0, 0, 0, .5); font-weight:normal;}
.no-data-container.medium{margin-top:50px;}
.no-data-container.medium img{width:200px;}
.no-data-container.medium p{font-size:18px; color:rgba(0, 0, 0, .5); font-weight:normal;}

.hidden{display:none !important;}

.table{border:1px solid #aaa; background-color:#fff;}
.table .MuiTableCell-head{border-right:1px solid #aaa; border-bottom:1px solid #aaa; padding:10px; background-color:rgba(0, 0, 0, .07); line-height:1.2;}
.table .MuiTableBody-root{border-top:1px solid #aaa;}

.table .MuiTableBody-root .MuiTableCell-root{border-right:1px solid #aaa; padding:10px; border-bottom:1px solid #aaa; line-height:1.1;}
.table .MuiTableBody-root .MuiTableCell-root.visited{background-color:rgba(73, 199, 102, 0.3)}
.table .MuiTableBody-root .MuiTableCell-root.cancelled{background-color:rgba(199, 73, 73, 0.3)}
.table .MuiTableBody-root .MuiTableCell-root.rejected{background-color:rgba(255, 245, 111, 0.3)}

.table .MuiTableBody-root .MuiTableCell-root.MuiTableCell-sizeSmall{padding:5px}

.table .MuiTableBody-root .MuiTableRow-root{transition:.2s all ease-in-out;}

.table .MuiTableRow-root.highlight-b-l{background-color:rgba(80, 127, 255, 0.15);}
.table .MuiTableRow-root.highlight-y-l{background-color:rgba(255, 229, 80, .15);}
.table .MuiTableRow-root.highlight-r-l{background-color:rgba(255, 80, 118, 0.15);}

.table.row-actions .MuiTableBody-root .MuiTableRow-root.row-level-actions{cursor:pointer}
.table.row-actions .MuiTableBody-root .MuiTableRow-root.row-level-actions:hover{background-color:rgb(106, 130, 251, .1);}

.form-wrapper{display:flex; align-items:center; justify-content:center;}
.form-wrapper.medium{width:600px;}
.form-wrapper.small .form-container{width:60%;}
.form-container{background-color:#fff; border:1px solid rgba(0, 0, 0, .15); width:100%}
.form-header{border-bottom:1px solid rgba(0, 0, 0, .15); padding:0; display:flex; align-items:stretch; justify-content:flex-start;}
.form-header-action{width:130px; border-right:1px solid rgba(0, 0, 0, .1)}
.form-header-block{padding:15px 20px 15px 35px}
.form-header h1{margin:0; font-size:16px; font-weight:bold; color:rgba(0, 0, 0, .8)}
.form-header .subtitle{text-transform:uppercase; color:#6a82fb; font-size:12px;}
.form-body{}
.form-bottom{border-top:1px solid rgba(0, 0, 0, .15); padding:20px 20px; display:flex; align-items:center; justify-content:space-between;}

.form-drawer.medium .MuiDrawer-paper{width:50%;}
.form-drawer.small .MuiDrawer-paper{width:34%;}

.form-drawer.medium.bottom .MuiDrawer-paper{left:25%;}
.form-drawer.small.bottom .MuiDrawer-paper{left:33%;}

/* .form-drawer.medium .MuiDrawer-paper{width:50%;}
.form-drawer.small .MuiDrawer-paper{width:34%;} */

.form-drawer .form-wrapper,
.form-drawer .form-wrapper .form-container{width:100%; border:0 none}

.form-drawer.right .form-body{height:calc(100vh - 153px); display:flex; align-items:stretch; overflow:auto;}
.form-drawer .form-body form{display:flex; align-items:stretch; width:100%;}
.form-drawer .form-tabbed{width:100%; align-self:baseline; min-height:calc(100vh - 153px)}
.form-drawer .form-body-wrapper{flex:1}

.form-drawer.right .MuiDrawer-paper{width:90%}

.form-drawer.right.medium .MuiDrawer-paper{width:65%}
.form-drawer.right.small .MuiDrawer-paper{width:34%}

.form-drawer.right .form-wrapper.full .form-bottom{position:relative;}

.form-drawer .MuiBackdrop-root{backdrop-filter:blur(5px); background-color:rgba(0, 0, 0, .3);}

.form-wrapper.full .form-bottom{position:fixed; bottom:0; right:0; background-color:#fff; z-index:99;}

.form-body-wrapper{padding:40px 60px 70px 60px;}
.form-body-wrapper h4.title{margin:0 0 20px 0; font-size:15px; color:rgba(0, 0, 0, .7)}
.form-body-wrapper h5.sub-heading{margin:30px 0 5px 0; font-size:13px; color:rgba(0, 0, 0, .7);}

.form-body-wrapper.high{padding:0}

.form-body-wrapper.high .form-rows-container > .MuiGrid-root{padding-left:40px;}
/* .form-rows-container > .MuiGrid-root:nth-child(even){background-color:rgba(0, 0, 0, 0.05);} */

.form-tabbed{display:flex; align-items:stretch; justify-content:flex-start; min-height:400px}
.form-tabs{border-right:1px solid rgba(0, 0, 0, .1); background-color:#f4f8fb; width:130px; padding-top:30px;}
.form-tab-fields{flex:1}

.form-tabs .MuiTabs-vertical{width:131px; padding-bottom:100px;}

.view{}
.view label{font-weight:600; font-size:13px; color:rgba(0,0,0,.8);}
.view div{color:rgba(0, 0, 0, .6); font-size:14px;}

.form-tabs .MuiTab-root{text-transform:capitalize; font-weight:600; font-size:13px;}
.MuiTabs-flexContainerVertical .Mui-selected{background-color:#fff; position:relative; border-top:1px solid rgba(0,0,0,.1); border-bottom:1px solid rgba(0,0,0,.1);}
.form-tabs .MuiTab-root.Mui-selected{color:#5d6cef;}
.form-tabs .MuiTabs-indicator{left:0; right:auto; background-color:#5d6cef;}
.form-tabs .MuiTabs-indicator:after{content:''; position:absolute; display:block; left:130px; width:1px; background-color:#fff; height:100%;}

.form-tabs .MuiTab-root.tab-error{color:#d32f2f}

.field-helper-text{margin-top:0; margin-bottom:4px; font-size:12px; color:rgba(0, 0, 0, .6)}

.wd-form-select .MuiInputLabel-root{transform:translate(0px, 16px) scale(1);}
.wd-form-select .MuiInputLabel-shrink{transform:translate(0, -1.5px) scale(0.75);}

.MuiFormControl-root .MuiFormLabel-root{font-size:13px; color:rgba(0,0,0,.8); font-weight:600}
.MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-shrink{font-size:16px; font-weight:600; transform:translate(0px, -9px) scale(0.75)}

.MuiFormControl-root label + .MuiInputBase-root{margin-top:10px;}

.MuiList-root .MuiMenuItem-root{font-size:13px;}

.MuiInputBase-root .MuiSelect-select{font-size:13px; font-weight:600}
.form-body-wrapper .MuiInputLabel-shrink{font-weight:600; font-size:17px; color:#1976d2}

.MuiFormControlLabel-root .MuiTypography-root{font-size:13px; font-weight:600}
.MuiFormControlLabel-root .MuiSvgIcon-root{width:15px;}

.MuiList-root{background-color:#eeeeee;}
.MuiInputBase-input.MuiInputBase-inputSizeSmall{font-size:13px;}

.MuiSwitch-root .MuiSwitch-switchBase{color:rgba(0, 0, 0, .5)}

.loader{position:absolute; width:100%; height:100%; display:flex; justify-content:center; align-items:center; top:0; background-color:rgba(255,255,255,.7); z-index:999;}

.MuiPickersLayout-root{box-shadow:0 10px 15px rgba(0,0,0,.2); border:1px solid rgba(0,0,0,.2)}

.inline-notification{display:flex; align-items:center; justify-content:center;}
.inline-notification.success{}

.inline-notification .message{padding:50px 0; display:flex; align-items:center; justify-content:center; flex-direction:column;}
.inline-notification .icon{width:80px; height:80px; display:flex; align-items:center; justify-content:center; border-radius:50%;}
.inline-notification .icon svg{}
.inline-notification .message-text{margin-top:10px; padding:0 50px; text-align:center;}
.inline-notification .message-text p{margin:0; font-weight:600;}
.inline-notification .message-action{margin-top:20px; padding-top:20px; border-top:1px solid rgba(0, 0, 0, .2); width:100%; display:flex; align-items:center; justify-content:center;}

.inline-notification.success .icon{background-color:rgba(143, 188, 143, .2);}
.inline-notification.success svg{color:rgb(78, 155, 78);}
.inline-notification.success .message-text{color:rgb(42, 127, 42);}

.upload-panel{cursor:pointer; display:flex; align-items:center; justify-content:center; position:relative; width:100%}
.upload-panel-button{display:flex; align-items:center; justify-content:center; flex-direction:column; width:50%; transition:.2s all ease; background-color:rgba(0,0,0,.05); border:0px dashed rgba(0, 0, 0, .1); border-radius:5px; padding:20px;}
.upload-panel-button:hover{box-shadow:0 5px 5px rgba(0, 0, 0, .1);}
.upload-panel-icon{display:flex; align-items:center; justify-content:center; width:45px; height:45px; background-color:#fff; border-radius:50%}
.upload-panel-button strong{opacity:.7; transition:.2s all ease-in-out; text-transform:uppercase; font-size:10px; margin-top:5px;}
.upload-panel-button:hover strong{opacity:.6}

.upload-panel.sm .upload-panel-button{width:70px; height:50px; text-align:center;}
.upload-panel.sm .upload-panel-icon{width:30px; height:30px; text-align:center;}
.upload-panel.sm strong{font-size:10px; text-align:center}

.upload-panel.lg .upload-panel-button{width:75%; padding:30px; text-align:center; border:1px dashed rgba(106, 130, 251, .5); background-color:rgba(106, 130, 251, .05);}
.upload-panel.lg .upload-panel-icon{width:100px; height:100px; text-align:center; background-color:rgba(106, 130, 251, .2);}
.upload-panel.lg .upload-panel-icon svg{color:rgba(106, 130, 251, .3); font-size:55px;}
.upload-panel.lg strong{font-size:14px; text-align:center; text-transform:capitalize;}
.upload-panel.lg .upload-panel-button:hover{box-shadow:0 0 20px rgba(0, 0, 0, .1); background-color:rgba(106, 130, 251, .1);}
.upload-panel.lg span{font-size:12px; color:rgba(0, 0, 0, .4)}

.uploaded-item{padding:6px; border:1px solid rgba(0,0,0,.2); border-radius:3px; position:relative}

.hoverable{cursor:pointer; padding:5px; transition:.2s all ease-in-out}
.hoverable:hover{background-color:rgba(0, 0, 0, .05);}

.image-items-grid{display:flex; justify-content:space-evenly; align-items:stretch; list-style-type:none; padding:0; margin:0; border:1px solid rgba(0, 0, 0, .4); background-color:#fff;}
.image-items-grid > li{border-right:1px solid rgba(0, 0, 0, .4); flex:1;}
.image-items-grid > li:last-child{border:0 none;}
.image-items-grid > li > ul{display:flex; align-items:flex-start; justify-content:flex-start; flex-direction:column; list-style-type:none; padding:0; margin:0;}
.image-items-grid > li > ul li h3{font-size:13px; text-align:center;}
.image-items-grid > li > ul > li{width:100%; border-bottom:1px solid rgba(0, 0, 0, .4); height:120px; display:flex; align-items:center;}
.image-items-grid > li > ul > li:last-child{border-bottom:0 none;}
.image-items-grid .heading{display:flex; align-items:center; justify-content:center; border-bottom:1px solid rgba(0, 0, 0, .4); height:30px;}

.inner-form .form-container{border:0 none}
.inner-form .form-tabs{background-color:transparent;}

.popover-confirm{width:450px; padding:20px; position:relative}
.popover-confirm.sm{width:250px}
.popover-confirm.lg{width:550px}

.popover-confirm-footer{padding-top:10px; border-top:1px solid rgba(0, 0, 0, .3); display:flex; align-items:center; justify-content:flex-end;}

.popover-actions{display:flex; justify-content:flex-end; align-items:center;}

.button-menu{background-color:transparent;}
.button-menu .MuiPaper-root{border-radius:6px !important}
.button-menu .popover-confirm{border-radius:6px; border:1px solid rgba(0, 0, 0, .1)}
.button-menu .MuiList-dense .MuiListItem-dense{border-bottom:1px solid rgba(0, 0, 0, .07)}
.button-menu .MuiList-dense .MuiListItem-dense:last-child{border:0 none}
.button-menu .MuiList-dense .MuiListItem-dense .MuiListItemIcon-root{min-width:30px}
.button-menu .MuiList-dense .MuiListItem-dense .MuiListItemIcon-root .MuiSvgIcon-root{width:20px; color:rgba(0, 0, 0, .4)}
.button-menu .MuiList-dense .MuiListItem-dense .MuiTypography-displayBlock{font-size:12.5px !important}

.welcome{display:flex; align-items:center; justify-content:center; flex-direction:column;}
.welcome h1{margin:0; font-size:40px; color:rgba(0, 0, 0, .15); font-weight:900; text-transform:uppercase;}
.welcome h2{font-size:30px; color:rgba(0, 0, 0, .5); margin:0; margin-bottom:30px; font-weight:900;}

.dashboard-links{}
.dashboard-links ul{list-style-type:none; padding:0; margin:0; display:flex; align-items:center; justify-content:space-between;}
.dashboard-links ul li{margin:10px; flex:1; align-items:center; justify-content:center;}
.dashboard-links ul li a{background-color:rgba(0, 107, 47, .8); display:flex; border-radius:10px; padding:30px; flex-direction:column; align-items:center; justify-content:center; transition:.2s all ease-in-out; text-decoration:none;}
.dashboard-links ul li a:hover{transform:translateY(-5px) scale(1.05); box-shadow:0 5px 10px rgba(0, 0, 0, .1);}
.dashboard-links ul li a .icon{width:70px; height:70px; display:flex; align-items:center; justify-content:center; background-color:rgba(255, 255, 255, .1); border-radius:50%}
.dashboard-links ul li a svg{font-size:40px; color:rgba(255,255,255, .5)}
.dashboard-links ul li a span{font-weight:bold; font-size:13px; color:rgba(255,255,255, 1); margin-top:15px; text-align:center; line-height:.5;}

.full-calendar{width:100%}

.full-calendar-toolbar{border-bottom:1px solid rgba(0, 0, 0, .2); padding:10px 0; display:flex; justify-content:space-between; align-items:center}
.full-calendar-title{font-weight:900; color:rgba(0, 0, 0, .5); font-size:20px;}

.full-calendar-month-actions{list-style-type:none; margin:0; padding:0; display:flex;}

.full-calendar .headers{display:flex; list-style-type:none; padding:0; margin:0; justify-content:space-evenly;}
.full-calendar .headers li{flex:1; margin-right:20px; border-bottom:1px solid rgba(0, 0, 0, .2); padding:10px;}
.full-calendar .headers li:last-child{margin-right:0;}

.full-calendar .days{display:flex; list-style-type:none; padding:0; margin:10px 0 0 0; justify-content:space-evenly;}
.full-calendar .days li{flex:1; margin-right:20px; height:80px;}
.full-calendar .days li.month-day{border-bottom:1px solid rgba(0, 0, 0, .2); padding-bottom:10px;}
.full-calendar .days li .month-day-block{height:calc(100% - 20px); padding:10px; border-radius:10px; transition:.2s all ease-in-out;}
.full-calendar .days li .month-day-block:hover{background-color:#fff;}
.full-calendar .days li:last-child{margin-right:0;}

.full-calendar .days li .day{font-size:20px; font-weight:900; color:rgba(0, 0, 0, .3)}
.full-calendar .days li .mark-label{color:rgb(255, 121, 80); font-size:12px; font-weight:600}

.status-bar-blocks{width:300px;}

.status-bar{display:flex; list-style-type:none; width:100%; margin:0; padding:0;}
.status-bar li{height:20px; transition:.2s all ease-in-out}
.status-bar li:first-child{border-radius:50px 0 0 50px}
.status-bar li:last-child{border-radius:0 50px 50px 0}

.status-bar-labels{display:flex; list-style-type:none; width:100%; margin:5px 0 0 0; padding:0; justify-content:space-around;}
.status-bar-labels li{display:flex; flex-direction:column; font-size:12px; align-content:center; justify-content:center;}
.status-bar-labels li strong{font-size:12px; text-transform:uppercase; color:rgba(0, 0, 0, .5)}
.status-bar-labels li .status-color{width:10px; height:10px; display:block}

.ck-content{min-height:200px; font-size:13px;}

.MuiAccordion-root.accordion{box-shadow:none; border:1px solid rgba(0, 0, 0, .2)}
.MuiAccordion-root.accordion .MuiAccordionSummary-root{min-height:20px; padding:10px 20px; background-color:rgba(0,0,0,.06); border-bottom:1px solid rgba(0,0,0,.2);}
.MuiAccordion-root.accordion .MuiAccordionSummary-content{margin:0}

.MuiPopover-root .MuiBackdrop-root{background-color:rgba(0, 0, 0, .2);}

.drag_over{transition:.2s all ease-in-out; margin-bottom:30px}
.drag_over > .menu-child{padding:5px; border:1px dashed #ccc; background-color:rgba(0, 0, 0, .03); height:20px; margin-left:30px;}

.box-block{border:1px solid rgba(0,0,0,.1); border-radius:5px; background-color:rgba(0,0,0,.02);}
.box-block strong{font-size:12px;}
.box-block-header{padding:5px 10px; border-bottom:1px solid rgba(0,0,0,.1); background-color:rgba(0,0,0,.05);}
.box-block-body{padding:20px;}

.ck-body-wrapper{position:absolute; z-index:9999;}

.tree-view{list-style-type:none; margin:0; padding:0; border-left:1px solid rgba(0, 0, 0, .2); padding-top:10px;}
.tree-view li{margin-left:25px; position:relative; margin-bottom:15px; border-bottom:1px solid rgba(0, 0, 0, .1); padding-bottom:10px;}
.tree-view li:before{content:''; display:block; position:absolute; width:15px; height:1px; background-color:rgba(0, 0, 0, .2); left:-25px; top:8px}

.category-search-container{min-height:200px; max-height:400px; overflow:scroll}
